import SourceFlowText from "@sourceflow-uk/sourceflowtext"
import {
    Container,
    Row,
    Col,
    Card,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Collapse
} from 'reactstrap';
import styles from './styles.module.scss';
import Link from "next/link"
import SimpleForm from "../SimpleForm";
import React, { useState } from 'react';
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

export default function GetInTouch({
    global,
    pathPrefix,
    customClass = "",
    content,
    content_2,
    content_3,
    buttonLink,
    buttonTitle,
    formId,
    successPageUrl,
    post,
    direction,
    popUpModal_1 = false,
    popUpModal_1_form_id,
    dialog_content_1,
    hard_coded_contacts,
    ...args

}) {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);

    const [state, setState] = React.useState({});
    const { article } = state;

    React.useEffect(() => {
        setState((prevState) => ({
            moreInfoOpen: true
        }));
        handleArticleOpen("0");

    }, []);

    const handleArticleOpen = (article) => {
        setState((prevState) => ({
            ...prevState,
            article
        }));

    };
    // const [selectedItem, setSelectedItem] = useState(post?.slice(0, 1).map((post) => post?.title));

    const [selectedItem, setSelectedItem] = useState("Select nearest office location");

    return (
        <section className={`${styles.root} ${customClass} py-1`}>
            <Container>
                <Row>
                    <Col md={6} className="pe-md-5 mb-5 mb-md-0">
                        {content ?
                            <div className="mb-4">
                                <SourceFlowText global={global} path={`${pathPrefix}.content`} type="html">
                                    {content}
                                </SourceFlowText>
                            </div> : null}
                        {hard_coded_contacts ?
                            <div class="hotlines">
                                {hard_coded_contacts}
                            </div> : null}
                            {post ? (
  <div className="rounded-4 primary-bg p-4 locations-wrapper mb-5">
    {/* Dropdown */}
    <Dropdown isOpen={dropdownOpen} toggle={toggle} direction={direction}>
      <DropdownToggle caret className="w-100 text-black">
        {selectedItem || "Select nearest office location"}
      </DropdownToggle>
      <DropdownMenu {...args}>
        {post.map((post, index) => (
          <DropdownItem
            key={index}
            onClick={() => {
              handleArticleOpen(`${index}`);
              setSelectedItem(post.title); // Update selected location
            }}
          >
            {post?.title}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>

    {/* Details rendering */}
    {selectedItem !== "Select nearest office location" &&
      post.map((post, index) => (
        <Collapse isOpen={article === `${index}`} key={index}>
          <div className="mt-4 text-black">
            <p className="m-0">
              <strong>Visit Us</strong>
            </p>
            {post?.address && (
              <div
                className="post-address"
                dangerouslySetInnerHTML={{ __html: post?.address }}
              />
            )}
            {post?.google_map_link && (
              <div className="mb-4">
                <Link href={`${post?.google_map_link}`}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="tertiaryBtn no-arrow"
                  >
                    Take me to Google Map
                  </a>
                </Link>
              </div>
            )}
            <p className="m-0">
              <strong>Send Us An Email</strong>
            </p>
            <p>
              <Link href={`mailto:${post?.email}`}>
                <a>{post.email}</a>
              </Link>
            </p>
            <p className="m-0">
              <strong>Give Us A Ring</strong>
            </p>
            <p>
              <Link href={`tel:${post?.telephone}`}>
                <a>{post.telephone}</a>
              </Link>
            </p>
          </div>
        </Collapse>
      ))}
  </div>
) : null}
                        {content_2 ?
                            <>
                                <hr></hr>
                                <div className="mb-4 contentWrapper">
                                    <SourceFlowText global={global} path={`${pathPrefix}.content_2`} type="html">
                                        {content_2}
                                    </SourceFlowText>
                                </div>
                            </> : null}
                        {popUpModal_1 ?
                            <button className="border-0 fancyBox primaryBtn no-arrow" data-src={`#dialog-content-1`}>
                                {buttonTitle}
                            </button> :
                            buttonLink ?
                                <div>
                                    <Link href={`${buttonLink}`}>
                                        <a className="tertiaryBtn no-arrow m-0">
                                            {buttonTitle}
                                        </a>
                                    </Link>
                                </div> : null
                        }
                        {/* // {buttonLink ?
                            //     <div>
                            //         <Link href={`${buttonLink}`}>
                            //             <a className="tertiaryBtn no-arrow m-0">
                            //                 {buttonTitle}
                            //             </a>
                            //         </Link>
                            //     </div> : null} */}
                        {
                            popUpModal_1_form_id ?
                                <div class="dialog-content" id="dialog-content-1" >
                                    <div>
                                        {dialog_content_1 ?
                                            <>
                                                <SourceFlowText global={global} path={`${pathPrefix}.dialog-content-1`} type="html">
                                                    {dialog_content_1}
                                                </SourceFlowText> <hr></hr>
                                            </> : null}

                                        <SimpleForm
                                            formId={`${popUpModal_1_form_id}`}
                                            successPageUrl={`${successPageUrl}`}
                                        />
                                    </div>
                                </div> : null}
                    </Col>
                    <Col md={6}>
                        {content_3 ?
                            <>
                                <div className="mb-4">
                                    <SourceFlowText global={global} path={`${pathPrefix}.content_3`} type="html">
                                        {content_3}
                                    </SourceFlowText>
                                </div>
                            </> : null}
                        {formId ?
                            <SimpleForm
                                formId={formId}
                                successPageUrl={successPageUrl}
                                customClass={`secondary-bg p-4 p-md-5 rounded-4`}
                            /> : null
                        }
                        {post?.map((post, index) =>
                            <Collapse isOpen={article === `${index}`} key={index}>
                                {post?.sourceflow_form_embed_api ?
                                    <SimpleForm
                                        formId={`${post?.sourceflow_form_embed_api}`}
                                        successPageUrl={successPageUrl}
                                        customClass={`bg-white p-4 p-md-5 rounded-4`}
                                    /> : null
                                }
                            </Collapse>
                        )}
                    </Col>
                </Row>
            </Container>
        </section >
    )
}